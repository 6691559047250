<template>
  <div class="content" v-if="model">
    <div class="content-top">
      <img :src="require('@/assets/images/banner-detail.png')" />
      <div class="content-top-content">
        <div class="work-author">
          {{
            model.category_name +
            (model.parent_category_name
              ? " · " + model.parent_category_name
              : "")
          }}
        </div>
        <div class="work-author" v-if="model.company.length > 0">
          {{ model.company }}
        </div>
        <div class="work-title">
          <block v-if="model.is_only_show == 0">{{ model.vote_code }}</block>
          {{ model.title }}
        </div>
      </div>
    </div>
    <div
      class="work-info"
      v-if="model.is_vote_active == 1 && model.is_only_show == '0'"
    >
      <div class="info-item">
        <span class="item-title">编号</span>
        <span class="item-value">{{ model.vote_code }}</span>
      </div>
      <div class="info-item">
        <span class="item-title">点赞</span>
        <span class="item-value">{{ model.total_score }}</span>
      </div>
      <div class="info-item">
        <span class="item-title">排名</span>
        <span class="item-value rank">{{ model.vote_ranking }}</span>
      </div>
    </div>

    <div class="work-intro" v-if="videoIds.length > 0">
      <div class="work-video-wrap">
        <div class="work-video-box">
          <div class="work-video">
            <div
              id="container"
              v-if="videoIds.length > 0"
              class="video-wrapper"
            >
              <div id="vidro-content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="work-intro" v-if="videoIds.length > 0">
      <div v-for="item in videoIds" :key="item">
        <div class="work-video-wrap">
          <div class="work-video-box">
            <div class="work-video">
              <div :id="'container' + item" class="video-wrapper">
                <div id="vidro-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="work-intro" v-if="model.intro.length > 0">
      <div v-html="$decodeContent(model.intro)"></div>
    </div>

    <div
      class="work-fav-detail"
      @click="toFav"
      v-if="model.is_vote_active == 1 && model.is_only_show == '0'"
    >
      <div class="icon-circle">
        <icon name="thumb-up-2" />
      </div>
      <span class="fav-total">{{ model.total_score }} 赞</span>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "tdesign-icons-vue-next";
import { Toast } from "tdesign-mobile-vue";
import { onActivated, onDeactivated, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { globals } from "../main.js";
import { doVote, worksDetail } from "../network/API";
import emitter from "../utils/mitt.js";
import tools from "../utils/tools.js";
import "../utils/txplayer.js";
const route = useRoute();

const model = ref(null);
const videoIds = ref([]);
const lastTap = ref(null);
const player = ref(null);

onMounted(() => {});

onActivated(() => {
  getWorksDetail(route.params.id);
});

onDeactivated(() => {
  console.log("deactivated");
  videoIds.value = [];
  player.value = null;
});

async function getWorksDetail(vid) {
  var res = await worksDetail(vid);
  if (res.vid) {
    videoIds.value.push(res.vid);
    playerInit(res.vid);
  } else {
    player.value = null;
    videoIds.value = [];
  }
  window.scrollTo({
    top: 0,
  });
  // if (res.vid2) {
  //   videoIds.value.push(res.vid2);
  //   playerInit(res.vid);
  // }
  // if (res.vid3) {
  //   videoIds.value.push(res.vid3);
  //   playerInit(res.vid);
  // }
  console.log(videoIds.value);

  model.value = res;

  tools.shareInit(res.title, res.image);
}
function playerInit(vid) {
  setTimeout(() => {
    player.value = new Txplayer({
      containerId: "container",
      vid: vid,
      width: "calc(100vw - 30px)",
      height: "178px",
      autoplay: false,
    });
  }, 1);
}
async function toFav() {
  let curInterval = new Date().getTime();
  if (lastTap.value && curInterval - lastTap.value < 3000) {
    Toast("点赞一次需要间隔3s");
    return;
  }
  globals.$showLoading();
  let res = await doVote({
    id: model.value.vote_id,
  });
  Toast.clear();
  Toast("点赞成功");
  model.value.total_score = res.score;
  lastTap.value = new Date().getTime();
  emitter.emit("refreshList");
}
</script>

<style scoped>
.content {
  background: #fff;
}
.content-top {
  position: relative;
  /* background: #fff url(../assets/images/banner-detail.png) no-repeat;
  background-size: 100%;
  text-align: center;

  min-height: 155px; */
}
.content-top-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 50px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container {
  justify-items: center;
}

::v-deep img {
  max-width: 100%;
}

.cover {
  width: 350px;
  height: 210px;
}

.work-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 700;
}

.work-author {
  color: #fff;
  font-size: 15px;
  margin-bottom: 15px;
}

.work-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 20px;
  margin: 0;
  color: #f7842c;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-title {
  font-size: 15px;
  margin-bottom: 6px;
}

.item-value {
  font-size: 20px;
  font-weight: bold;
}

.work-intro {
  font-size: 16px;
  line-height: 1.8rem;
  color: #333;
  margin: 15px;
  /* margin-bottom: 25px;

  margin-top: 10px;
  background: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  box-shadow: 0 15px 15px 0 rgba(69, 42, 124, 0.15); */
  border-radius: 10px;
}

.work-video {
  /* background: #8848e0;
  padding: 4px;
  border-radius: 10px;
  border: 4px solid #a162f7; */
}

.work-video-wrap {
  position: relative;
}
.shape {
  position: absolute;
  right: -15px;
  top: -50px;
}

.video-box-top {
  width: 687px;
  height: 26px;
  display: block;
  margin-left: 1px;
  margin-right: 1px;
  display: block;
}

.work-video-box {
  /* background-color: #caa3ff; */
  /* padding: 8px; */
  border-radius: 10px;
  overflow: hidden;
}

.work-video video {
  border-radius: 10px !important;
  overflow: hidden;
}

.player-index--container {
  background-color: transparent !important;
}

.work-fav-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  margin: 0 auto;
}

.fav-icon {
  width: 110px;
  height: 110px;
  justify-self: center;
  margin-bottom: 10px;
}

.fav-total {
  font-size: 18px;
  color: #222;
  margin-top: 10px;
  margin-bottom: 20px;
}
.icon-circle {
  background: linear-gradient(90deg, #ff7300 0%, #ee2e1b 100%);
  border-radius: 50%;
  color: #fff;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
}
</style>
